<template>
    <div class="me">
        <!-- 头部 -->
        <div class="top">
            <div class="header">
                <div class="left">
                    <van-icon name="arrow-left" class="icon" />
                </div>
                <div class="title">我的</div>
                <div class="btn" @click="gopath('/set')">
                    <van-icon name="setting-o" />
                </div>
            </div>
            <div class="user">
                <div class="left">
                    <van-uploader :after-read="afterRead">
                        <img v-if="userData.headPic" :src="userData.headPic" class="userPic" />
                        <img v-else src="https://file.saen.com/default_head.jpg" class="userPic" />
                    </van-uploader>
                </div>
                <div class="center">
                    <!-- 已登录显示昵称 -->
                    <div v-if="userData.nickName" class="center_top">
                        <div class="name">{{ userData.nickName }}</div>
                        <div class="phone">{{ userData.mobile }}</div>
                    </div>
                    <!-- 未登录显示登录字段 -->
                    <div v-else class="center_top2" @click="toLogin">
                        <div class="center_top2_div1">登录/注册</div>
                        <div class="center_top2_div2">立即登录享受优质体验</div>
                    </div>
                    <div class="center_bottom" v-if="userData.isVip == 1">
                        <img src="../../assets/all_Icon/drawable-xxhdpi/icno_dy_vip.png" class="vipImg" />
                        <div class="expire">{{ userData.vipEndTime }} 到期</div>
                    </div>
                </div>
                <div class="right">
                    <van-icon @click.stop="gopath('/edituser')" name="edit" />
                    <van-icon @click.stop="showerweima($event)" name="qr-invalid" />
                </div>
            </div>
            <div class="message">
                <div class="item" @click="gopath('/message')">
                    <div class="val">{{ userData.unReadCount }}</div>
                    <div class="key">我的消息</div>
                </div>
                <div class="item" @click="gopath('/coupon')">
                    <div class="val">{{ userData.couponCount }}</div>
                    <div class="key">优惠券</div>
                </div>
                <div class="item" @click="gopath('/integral')">
                    <div class="val">{{ userData.myPoints }}</div>
                    <div class="key">我的积分</div>
                </div>
            </div>
        </div>

        <!-- 列表 -->
        <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index" @click="gopath2(item)">
                <img :src="item.icon" class="leftimg" />
                <div class="itemname">{{ item.title }}</div>
                <div class="rightIcon">
                    <van-icon name="arrow" />
                </div>
            </div>
        </div>

        <!-- 二维码弹窗 -->
        <van-dialog v-model="showerweiModal" :showConfirmButton="false" :closeOnClickOverlay="true">
            <div class="content">
                <img :src="picCode" class="img" />
                <div class="text">
                    <div>保存图片识别二维码</div>
                    <div>关注公众号领好礼</div>
                </div>
            </div>
        </van-dialog>

        <!-- 底部tabbar -->
        <tabbar />
    </div>
</template>

<script>
import Tabbar from "@/components/tabBar.vue";

import { user } from "@/api/user.js";
import { setUpload } from "@/api/selectClass";
export default {
    components: { Tabbar },
    data() {
        return {
            userData: {},
            list: [
                {
                    icon: require("../../assets/all_Icon/drawable-xxhdpi/icon_my_order.png"),
                    title: "我的订单",
                    path: "/orderlist",
                },
                {
                    icon: require("../../assets/all_Icon/drawable-xxhdpi/icon_my_shoucang.png"),
                    title: "我的收藏",
                    path: "/collection",
                },
                {
                    icon: require("../../assets/all_Icon/drawable-xxhdpi/icon_my_duihuanquan.png"),
                    title: "我要兑换",
                    path: "/exchange",
                },
                // {
                //   icon: require("../../assets/all_Icon/drawable-xxhdpi/icon_my_wd.png"),
                //   title: "我的问答",
                //   path: "/order",
                // },
                {
                    icon: require("../../assets/all_Icon/drawable-xxhdpi/icon_my_yijianfankui.png"),
                    title: "意见反馈",
                    path: "/feedback",
                },
                {
                    icon: require("../../assets/all_Icon/drawable-xxhdpi/icon_my_yonghuxieyi.png"),
                    title: "用户协议",
                    path: "/me/detail",
                },
                {
                    icon: require("../../assets/all_Icon/drawable-xxhdpi/icon_my_changjianwenti.png"),
                    title: "常见问题",
                    path: "/problem",
                },
                {
                    icon: require("../../assets/all_Icon/drawable-xxhdpi/icon_my_yinsi.png"),
                    title: "隐私政策",
                    path: "/me/detail",
                },
                {
                    icon: require("../../assets/all_Icon/drawable-xxhdpi/icon_my_kefu.png"),
                    title: "联系客服",
                    // path: "http://www.canet.com.cn/kefu/",
                    path: "https://tb.53kf.com/code/client/10093167/1",
                },
                {
                    icon: require("../../assets/all_Icon/drawable-xxhdpi/icon_my_gywm.png"),
                    title: "关于我们",
                    path: "/me/detail",
                },
            ],
            showerweiModal: false,
        };
    },
    created() {
        this.getUser();
    },
    name: "me",
    methods: {
        afterRead(file) {
            // 上传头像 此时可以自行将文件上传至服务器
            let formData = new FormData();
            formData.append("files", file.file);
            setUpload(formData).then((res) => {
                if (res.data.data[0]) {
                    this.userData.headPic = res.data.data[0];
                }
            });
        },
        toLogin() {
            this.$router.push("/login");
        },
        gopath(val) {
            this.$router.push({ path: val });
        },
        getUser() {
            user().then((res) => {
                console.log(res);
                this.userData = res.data.data;
            });
        },
        showerweima(e) {
            if (e.currentTarget.className.indexOf("van-icon-qr-invalid") > -1) {
                this.showerweiModal = true;
            }
        },
        gopath2(val) {
            if (val.title == "联系客服") return (window.location.href = "https://tb.53kf.com/code/client/10093167/1");
            this.$router.push({ path: val.path, query: { title: val.title } });
        },
    },
};
</script>

<style lang="less" scoped>
.me {
    width: 100%;
    min-height: 100%;
    background: #f5f5f5;
    padding-bottom: 50px;
    .top {
        background: url("../../assets/all_Icon/drawable-xxhdpi/bg_my_home.png")
            no-repeat;
        background-size: 100% 100%;
        position: relative;
        margin-bottom: 80px;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        right: 0;
        z-index: 99;
        .header {
            padding: 10px 10px;

            display: flex;
            .left {
                width: 10%;
                .icon {
                    font-size: 24px;
                    font-weight: 550;
                    color: #fff;
                }
            }
            .title {
                width: 85%;
                text-align: center;
                font-size: 17px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #fff;
            }
            .btn {
                font-size: 17px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #fff;
            }
        }
        .user {
            display: flex;
            align-items: center;
            color: #fff;
            padding: 29px 14px 73px;
            .left {
                width: 20%;
                .userPic {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                }
            }
            .center {
                margin-left: 10px;
                width: 63%;
                .center_top {
                    display: flex;
                    align-items: center;
                    .name {
                        font-size: 15px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #ffffff;
                        line-height: 22px;
                    }
                    .phone {
                        font-size: 13px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #ecf1fe;
                        line-height: 18px;
                        margin-left: 5px;
                    }
                }
                .center_top2 {
                    .center_top2_div1 {
                        font-size: 16px;
                        margin-bottom: 3px;
                    }
                    .center_top2_div2 {
                        font-size: 12px;
                    }
                }
                .center_bottom {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    .vipImg {
                        width: 46px;
                        height: 16px;
                        border-radius: 2px; /*no*/
                    }
                    .expire {
                        margin-left: 5px;
                    }
                }
            }
            .right {
                .van-icon {
                    font-size: 17px;
                    &:first-child {
                        margin-right: 9px;
                    }
                }
            }
        }
        .message {
            position: absolute;
            bottom: -50px;
            left: 0;
            right: 0;
            margin: 0 auto;
            padding: 36px 14px 27px;
            display: flex;
            align-items: center;
            width: calc(100% - 28px);
            background: #fff;
            border-radius: 9px 9px 0 0; /*no*/
            box-sizing: border-box;
            .item {
                width: 33%;
                text-align: center;
                border-right: 1px solid #dddddd;
                &:last-child {
                    border: none;
                }
                .val {
                    font-size: 22px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #141414;
                    line-height: 30px;
                }
                .key {
                    margin-top: 3px;
                    font-size: 13px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 18px;
                }
            }
        }
    }
    .list {
        padding: 270px 18px 0;
        .item {
            padding: 12px 0;
            border-bottom: 1px solid #dddddd;
            display: flex;
            align-items: center;
            &:last-child {
                border: none;
            }
            .leftimg {
                width: 18px;
                height: 17px;
                margin-right: 11px;
            }
            .itemname {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 20px;
                width: 90%;
            }
            .right {
            }
        }
    }
}
.content {
    padding: 21px 29px;
    background: #fff;
    text-align: center;
    .img {
        margin-bottom: 16px;
        width: 100%;
    }
    .text {
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 18px;
    }
}
</style>